a {
  color: #000;
  text-decoration: none;
}

a:hover {
  color: var(--mui-primary-color);
  /* Use CSS variable for theme primary color */
}

a.active {
  color: var(--mui-primary-color);
}